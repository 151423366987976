const intakeInfo = {
  companyName: "VTS Web Design",
  companyAddress: "La Saponaire, Val Thorens, 73440, France",
  domainName: "www.vts-webdesign.com",
  contactEmail: "contact@vts-webdesign.com",

  siteName: "VTS Web Design",
  highlightColor: "#40c8e0",

  taxNumber: "1313",
};

export default intakeInfo;
